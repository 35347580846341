<template>
  <div class="invoice-data-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container class="invoice-data">
      <h1>{{ $t("paymentInvoice.billingInformation") }}</h1>
      <PaymentInvoice :showTitle="false" />
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<script>
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";

import categoryMixin from "~/mixins/category";

export default {
  name: "InvoiceData",
  components: {
    PaymentInvoice
  },
  mixins: [categoryMixin]
};
</script>
